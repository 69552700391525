import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import { useParams } from "react-router-dom";
import AddAccountCom from "./AddAccount";
import { fetchDataWithRetries } from "../../Component/function/FunctionApi";
import "./AddAccount.css";

function AddAccount() {
    const [accounts, setAccounts] = useState([]); 
    const [filteredAccount, setFilteredAccount] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const getAccountData = async () => {
            try {
                await fetchDataWithRetries("Account/accounts", setAccounts);
            } catch (error) {
                console.error("Error fetching account data:", error);
            }
        };

        getAccountData();
    }, []);

    useEffect(() => {
        if (Array.isArray(accounts) && accounts.length > 0 && id) {
            const filtered = accounts.find(account => account.id === id);
            setFilteredAccount(filtered);
        }
    }, [accounts, id]); 

    if (!accounts) {
        return <div>Loading...</div>;
    }

    return (
        <div className="apDiv Accounts">
            <Sidebar />
            <div className="body_container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <AddAccountCom accountData={filteredAccount} />
            </div>
        </div>
    );
}

export default AddAccount;
