import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, Checkbox, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faSave } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import axios from 'axios'; 
import './TableBills.css'; 

export default function DataTable() {
  const [rows, setRows] = useState([]);
  const [searchTerms, setSearchTerms] = useState({
    id: '',
    date: '',
    client: '',
    address: '',
    governorate: '',
    phone: '',
    categories: '',
    itemCount: '',
    DailyHistory: '',
    delegate: '',
    status: '',
    total: '',
  });
  const [selectedRows, setSelectedRows] = useState([]);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/Invoice/get-invoice-pagination'); // API call
        if (response.data.statusCode === 200) {
          const apiData = response.data.data.map(item => ({
            id: item.id,
            date: item.invoiceDate.split('T')[0], // Format date
            client: item.customer,
            address: item.address,
            governorate: item.governorate?.name || '', // Handle optional chaining
            phone: item.phone,
            categories: item.category?.name || '',
            itemCount: item.number || 0,
            DailyHistory: item.dailyDate?.split('T')[0] || '',
            delegate: item.representative?.fullName || '',
            status: item.status,
            total: item.category?.price || 0 // Assuming the total corresponds to the category price
          }));
          setRows(apiData); // Set the state with API data
        }
      } catch (error) {
        console.error('Error fetching data:', error); // Log errors
      }
    };

    fetchData(); // Call fetch function
  }, []); // Empty dependency array to run once on mount

  // Handle search input for all columns
  const handleSearch = (column, value) => {
    setSearchTerms(prev => ({ ...prev, [column]: value }));
  };

  // Filter rows based on search terms
  const filteredRows = rows.filter(row => {
    return Object.keys(searchTerms).every(column => {
      const searchTerm = searchTerms[column].toLowerCase();
      return row[column].toString().toLowerCase().includes(searchTerm);
    });
  });

  // Handle select all
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(filteredRows.map(row => row.id));
    } else {
      setSelectedRows([]);
    }
  };

  // Handle row selection
  const handleRowSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedRows(prev => [...prev, row.id]);
    } else {
      setSelectedRows(prev => prev.filter(id => id !== row.id));
    }
  };

  // Export to Excel
  const handleExportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'فواتير');
    XLSX.writeFile(wb, 'فواتير.xlsx');
  };

  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px' }}>
        <Button onClick={handleExportToExcel}>
          <FontAwesomeIcon icon={faSave} /> تصدير إلى Excel
        </Button>
        <Button onClick={() => console.log('طباعة')}>
          <FontAwesomeIcon icon={faPrint} /> طباعة
        </Button>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" style={{ width: '50px' }}>
                <Checkbox
                  checked={selectedRows.length === filteredRows.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell className="table-header">كود</TableCell>
              <TableCell className="table-header">التاريخ</TableCell>
              <TableCell className="table-header">العميل</TableCell>
              <TableCell className="table-header">العنوان</TableCell>
              <TableCell className="table-header">المحافظة</TableCell>
              <TableCell className="table-header">الهاتف</TableCell>
              <TableCell className="table-header">الفئات</TableCell>
              <TableCell className="table-header">عدد العناصر</TableCell>
              <TableCell className="table-header">التاريخ اليومي</TableCell>
              <TableCell className="table-header">المندوب</TableCell>
              <TableCell className="table-header">الحالة</TableCell>
              <TableCell className="table-header">الإجمالي</TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.id}
                  onChange={(e) => handleSearch('id', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.date}
                  onChange={(e) => handleSearch('date', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.client}
                  onChange={(e) => handleSearch('client', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.address}
                  onChange={(e) => handleSearch('address', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.governorate}
                  onChange={(e) => handleSearch('governorate', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.phone}
                  onChange={(e) => handleSearch('phone', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.categories}
                  onChange={(e) => handleSearch('categories', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.itemCount}
                  onChange={(e) => handleSearch('itemCount', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.DailyHistory}
                  onChange={(e) => handleSearch('DailyHistory', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.delegate}
                  onChange={(e) => handleSearch('delegate', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.status}
                  onChange={(e) => handleSearch('status', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="بحث"
                  value={searchTerms.total}
                  onChange={(e) => handleSearch('total', e.target.value)}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map(row => (
              <TableRow key={row.id} className={selectedRows.includes(row.id) ? 'selected-row' : ''}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={(event) => handleRowSelect(event, row)}
                  />
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.client}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.governorate}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.categories}</TableCell>
                <TableCell>{row.itemCount}</TableCell>
                <TableCell>{row.DailyHistory}</TableCell>
                <TableCell>{row.delegate}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
