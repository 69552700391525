import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchDataWithRetries, postDatatoQueryParams, putData, deleteFunction } from '../../../Component/function/FunctionApi';

function ComponentItemsGroup() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newProvince, setNewProvince] = useState({ id: '', name: '', groupName: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const [groupsData, setGroupsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [apiStatus, setApiStatus] = useState(null);

    const [message, setMessage] = useState(null); 
    const [messageType, setMessageType] = useState(''); // success or error

    useEffect(() => {
        const fetchGroups = async () => {
            setLoading(true);
            await fetchDataWithRetries('Group/get-groups', setGroupsData, setApiStatus);
            setLoading(false);
        };

        fetchGroups();
    }, []);

    const handleOpenModalForEdit = (group) => {
        setIsModalOpen(true);
        setIsEditMode(true);
        setNewProvince(group);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProvince((prevData) => ({ ...prevData, [name]: value }));
    };

    // دالة لإخفاء الرسالة بعد فترة أو عند الضغط على زر الإغلاق
    const handleCloseMessage = () => {
        setMessage(null);
    };

    const handleSave = async () => {
        const params = {
            name: newProvince.name,
            groupName: newProvince.groupName,
        };

        try {
            if (isEditMode) {
                const response = await putData(`Group/update-group/${newProvince.id}`, params);
                console.log('Group updated successfully:', response.data);
                setGroupsData((prevData) =>
                    prevData.map((group) => (group.id === newProvince.id ? response.data : group))
                );
                setMessage("تم التعديل بنجاح!");
                setMessageType("success");
            } else {
                const response = await postDatatoQueryParams('Group/create-group', params);
                console.log('Group added successfully:', response.data);
                setGroupsData((prevData) => [...prevData, response.data]);
                setMessage("تمت الإضافة بنجاح!");
                setMessageType("success");
            }
        } catch (error) {
            console.error(isEditMode ? 'Error updating group:' : 'Error adding group:', error);
            setMessage("حدث خطأ أثناء العملية.");
            setMessageType("error");
        }
        handleCloseModal();
    };

    const handleDelete = async () => {
        try {
            await deleteFunction(`Group/delete-group/${newProvince.id}`);
            console.log('Group deleted successfully');
            setGroupsData((prevData) => prevData.filter((group) => group.id !== newProvince.id));
            setMessage("تم الحذف بنجاح!");
            setMessageType("success");
            handleCloseModal();
        } catch (error) {
            console.error('Error deleting group:', error);
            setMessage("حدث خطأ أثناء عملية الحذف.");
            setMessageType("error");
        }
    };

    return (
        <div className="provinces-container">
            <div className="header-section">
                <h2>مجموعة الاصناف</h2>
                <button className="add-button" onClick={() => {
                    setIsModalOpen(true);
                    setIsEditMode(false);
                    setNewProvince({ id: '', name: '', groupName: '' });
                }}>
                    اضافه مجموعة +
                </button>
            </div>

            {loading ? (
                <p>جاري التحميل...</p>
            ) : (
                <table className="provinces-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>الاسم</th>
                            <th>اسم المجموعة</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupsData.map((group) => (
                            <tr key={group.id} onClick={() => handleOpenModalForEdit(group)}>
                                <td>{group.id}</td>
                                <td>{group.name}</td>
                                <td>{group.groupName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>{isEditMode ? 'تعديل المجموعة' : 'مجموعة جديد'}</h3>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="modal-close"
                                onClick={handleCloseModal}
                            />
                        </div>
                        <hr />
                        <div className="modal-body">
                            <div className="form-row">
                                <div className="form-group">
                                    <label>الاسم</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={newProvince.name}
                                        onChange={handleInputChange}
                                        placeholder="الاسم"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>اسم المجموعة</label>
                                    <input
                                        type="text"
                                        name="groupName"
                                        value={newProvince.groupName}
                                        onChange={handleInputChange}
                                        placeholder="اسم المجموعة"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="action-button" onClick={handleSave}>
                                <FontAwesomeIcon icon={faSave} /> {isEditMode ? 'تحديث' : 'حفظ'}
                            </button>
                            {isEditMode && (
                                <button className="action-button" onClick={handleDelete}>
                                    <FontAwesomeIcon icon={faTrash} /> حذف
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {message && (
                <div className={`modal-overlay ${messageType}`}>
                    <div className="modal-content">
                        <p>{message}</p>
                        <button className="icondeleteModal btn-colose" onClick={handleCloseMessage}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ComponentItemsGroup;
