import { React, useEffect, useState } from "react"
import { postDatatoQueryParams } from "../../Component/function/FunctionApi"
import "./Login.css"
import animation from "../../Component/img/Loader.gif"
import { useNavigate } from 'react-router-dom';

export function setCookie(name, value, hours) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000);
    const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};expires=${expirationDate.toUTCString()};path=/`;
    document.cookie = cookieString;
}

export function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return decodeURIComponent(cookie.substring(name.length + 1));
        }
    }
    return null;
}
function isCookieValid(name) {
    const cookieValue = getCookie(name);
    return cookieValue !== null;
}

function Login() {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [data, setData] = useState({
        userName: "",
        password: "",
    })

    const isUserLoggedIn = isCookieValid('user');

    useEffect(() => {
        if (isUserLoggedIn) {
            navigate('/home-page');
        } else {
            setLoading(false);
        }
    }, [])

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const userData = {
        UserName: data.userName,
        Password: data.password,
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);
        try {
            const result = await postDatatoQueryParams("Account/login", userData, setError);
            if (result) {
                setCookie('user', JSON.stringify(result.data), 12);
                setLoading(false);
                navigate('/home-page');
            }
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            {loading ?
                <div className="loading login">
                    <img className="animation" src={animation} alt="" />
                </div>
                :
                <div className="Login">
                    <div className="container">
                        <form onSubmit={(e) => handleFormSubmit(e)} dir="rtl">
                            <label htmlFor="userName">اسم المستخدم</label>
                            <input
                                type="text"
                                id="userName"
                                value={data.userName}
                                onChange={(e) => handle(e)}
                            />

                            <label htmlFor="password">كلمة المرور</label>
                            <input
                                type="password"
                                id="password"
                                value={data.password}
                                onChange={(e) => handle(e)}
                            />

                            <button type="submit">تسجيل دخول</button>

                            {error && <p>البيانات غير صحيحة، حاول مرة أخرى</p>}
                        </form>
                    </div>
                </div>

            }
        </>
    )
}

export default Login;