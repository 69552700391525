import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Invoices.css';

const Invoices = () => {
    const [invoicesData] = useState([
        { id: 1, date: '2024-01-01', client: 'عميل 1', phone: '01234567890', address: 'عنوان 1', governorate: 'القاهرة', secretary: 'سكرتيرة 1', itemCount: 5, dailyDate: '2024-01-01', delegate: 'مندوب 1', status: 'مفعل', visible: true },
        { id: 2, date: '2024-01-02', client: 'عميل 2', phone: '01234567891', address: 'عنوان 2', governorate: 'الجيزة', secretary: 'سكرتيرة 2', itemCount: 3, dailyDate: '2024-01-02', delegate: 'مندوب 2', status: 'غير مفعل', visible: true },
        // Add more sample data as needed
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const handleToggleVisibility = (id) => {
        // Implement visibility toggle logic if necessary
    };

    // Get current items
    const indexOfLastInvoice = currentPage * itemsPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - itemsPerPage;
    const currentInvoices = invoicesData.slice(indexOfFirstInvoice, indexOfLastInvoice);

    const totalPages = Math.ceil(invoicesData.length / itemsPerPage);

    return (
        <div className="invoices-container">
            <h2>فواتير</h2>
            <hr />
            <div className='containerInput'>
                <div className="input-group">
                    <label>
                        الكود
                        <input type="text" placeholder="الكود" />
                    </label>
                    <label>
                        التاريخ
                        <input type="date" />
                        
                    </label>
                    <label>
                        العميل
                        <input type="text" placeholder="العميل" />
                    </label>
                    <label>
                        التليفون
                        <input type="text" placeholder="التليفون" />
                    </label>
                    <label>
                        العنوان
                        <input type="text" placeholder="العنوان" />
                    </label>
                </div>
                <div className="input-group">
                    <label>
                        المستخدم
                        <input type="text" placeholder="المستخدم" />
                    </label>
                    <label>
                        المندوب
                        <input type="text" placeholder="المندوب" />
                    </label>
                    <label>
                        الحالة
                        <input type="text" placeholder="الحالة" />
                    </label>
                    <label>
                        الكمية
                        <input type="number" placeholder="الكميه" />
                    </label>
                    <label>
                        المحافظة
                        <input type="text" placeholder="المحافظة" />
                    </label>
                </div>
            </div>

            <div className="table-container">
                <div className="scrollable-table">
                    <table className="invoices-table">
                        <thead>
                            <tr>
                                <th>الكود</th>
                                <th>التاريخ</th>
                                <th>العميل</th>
                                <th>التليفون</th>
                                <th>العنوان</th>
                                <th>المحافظة</th>
                                <th>السكرتيرة</th>
                                <th>عدد الأصناف</th>
                                <th>تاريخ اليومية</th>
                                <th>المندوب</th>
                                <th>الحالة</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentInvoices.map((invoice) => (
                                <tr key={invoice.id} style={{ backgroundColor: 'white' }}>
                                    <td>
                                        {invoice.id}
                                    </td>
                                    <td>{invoice.date}</td>
                                    <td>{invoice.client}</td>
                                    <td>{invoice.phone}</td>
                                    <td>{invoice.address}</td>
                                    <td>{invoice.governorate}</td>
                                    <td>{invoice.secretary}</td>
                                    <td>{invoice.itemCount}</td>
                                    <td>{invoice.dailyDate}</td>
                                    <td>{invoice.delegate}</td>
                                    <td>{invoice.status}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={invoice.visible ? faEye : faEyeSlash}
                                            onClick={() => handleToggleVisibility(invoice.id)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="pagination">
                    <span>صفحة {currentPage} من {totalPages}</span>
                    <button onClick={() => setCurrentPage(Math.max(1, currentPage - 1))} disabled={currentPage === 1}>السابق</button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => setCurrentPage(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))} disabled={currentPage === totalPages}>التالي</button>
                </div>
            </div>
        </div>
    );
};

export default Invoices;
