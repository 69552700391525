import React, { useState } from 'react';
import './ChatApp.css';

const ChatWindow = ({ selectedFriend, messages, onSendMessage }) => {
    const [newMessage, setNewMessage] = useState(''); // State to hold new message

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            onSendMessage(newMessage);
            setNewMessage(''); // Clear the input field after sending
        }
    };

    return (
        <div className="chat-window">
            <div className="chat-header">
                <img src={selectedFriend.avatar} alt={`${selectedFriend.name} avatar`} />
                <h4>{selectedFriend.name}</h4>
            </div>
            <div className="chat-body">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message ${message.fromMe ? 'from-me' : 'from-them'}`}
                    >
                        <img src={message.fromMe ? selectedFriend.avatar : selectedFriend.avatar} alt="avatar" />
                        <div className="text">
                            {message.text}
                            <div className="timestamp">{message.timestamp}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="chat-footer">
                <input 
                    type="text" 
                    placeholder="Type a message" 
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)} // Update message state
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()} // Send message on Enter key press
                />
                <button className="send-button">ارسال</button> {/* Send button */}
            </div>
        </div>
    );
};

export default ChatWindow;
