import React, { useState } from 'react';
import SidebarChat from './SidebarChat';
import ChatWindow from './ChatWindow';
import img  from "../../Component/img/team-01.png"

import './ChatApp.css';


function CompontChatApp() {
    const [friends] = useState([
        { id: 1, name: 'صديق 1', lastMessage: 'كيف حالك؟', avatar: img},
        { id: 2, name: 'صديق 2', lastMessage: 'أين كنت؟', avatar: img },
        { id: 3, name: 'صديق 3', lastMessage: 'هل يمكنك مساعدتي؟', avatar: img },
        { id: 4, name: 'صديق 3', lastMessage: 'هل يمكنك مساعدتي؟', avatar: img },
        { id: 5, name: 'صديق 3', lastMessage: 'هل يمكنك مساعدتي؟', avatar: img },
        { id: 6, name: 'صديق 3', lastMessage: 'هل يمكنك مساعدتي؟', avatar: img },
        { id: 7, name: 'صديق 3', lastMessage: 'هل يمكنك مساعدتي؟', avatar: img },
        { id: 8, name: 'صديق 3', lastMessage: 'هل يمكنك مساعدتي؟', avatar: img },

    ]);

    const [selectedFriendId, setSelectedFriendId] = useState(friends[0].id);
    const [messages] = useState([
        { text: 'مرحبا', fromMe: true },
        { text: 'أهلا!', fromMe: false },
        { text: 'كيف حالك؟', fromMe: true },
    ]);

    const selectedFriend = friends.find(friend => friend.id === selectedFriendId);

    return (
        <div className="ChatApp" id="ChatApp">
            <ChatWindow selectedFriend={selectedFriend} messages={messages} />
            <SidebarChat
                friends={friends}
                selectedFriendId={selectedFriendId}
                onFriendSelect={setSelectedFriendId}
            />
        </div>
    );
}

export default CompontChatApp;
