import React, { useState, useEffect } from "react";
import "./AddAccount.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { Url } from "../../Component/function/FunctionApi";

function AddAccount({ accountData }) {
    const [formData, setFormData] = useState({
        image: null,
        name: "",
        displayName: "",
        password: "",
        userTypeValue: "admin" 
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (accountData) {
            setFormData({
                image: accountData.userImage || null,
                name: accountData.userName || "",
                displayName: accountData.displayName || "",
                password: "",
                userTypeValue: accountData.userType || "admin"
            });
        } else {
            resetForm();
        }
    }, [accountData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, image: file });
        }
    };

    const resetForm = () => {
        setFormData({
            image: null,
            name: "",
            displayName: "",
            password: "",
            userTypeValue: "admin"
        });
    };

    const handleSaveAccount = async () => {
        if (isSubmitting) return;

        setIsSubmitting(true);
        setIsSuccess(false);
        try {
            const form = new FormData();

            if (accountData) {
                // Only send fields that have changed
                if (formData.displayName !== accountData.displayName) form.append("DisplayName", formData.displayName);
                if (formData.password) form.append("Password", formData.password);
                if (formData.image && formData.image !== accountData.userImage) form.append("UserImage", formData.image);
                if (formData.userTypeValue !== accountData.userType) form.append("UserType", formData.userTypeValue);

                const response = await axios.put(`${Url}Account/update-account`, form, {
                    params: { userName: accountData.displayName }, 
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                handleResponse(response, "Account has been updated successfully!");
            } else {
                form.append("UserName", formData.name);
                form.append("DisplayName", formData.displayName);
                form.append("Password", formData.password);
                if (formData.image) form.append("UserImage", formData.image);
                form.append("UserType", formData.userTypeValue);

                const response = await axios.post(`${Url}Account/register`, form, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                handleResponse(response, "Account has been added successfully!");
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || "An unexpected error occurred. Please try again.";
            setModalContent(errorMessage);
            setModalVisible(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleResponse = (response, successMessage) => {
        const message = response?.data?.message || successMessage;
        setModalContent(message);
        setIsSuccess(response.status === 200);
        setModalVisible(true);
    };

    const isFormValid = () => {
        return (
            formData.displayName &&
            (!accountData || formData.password) // Password required only for new account
        );
    };

    const closeModal = () => {
        setModalVisible(false);
        setModalContent("");
    };

    return (
        <div className="add-account-container">
            <div className="profile-picture-container">
                <div className="profile-picture">
                    {formData.image ? (
                        typeof formData.image === 'string' ? (
                            <img src={formData.image} alt="Profile" />
                        ) : (
                            <img src={URL.createObjectURL(formData.image)} alt="Profile" />
                        )
                    ) : (
                        <FontAwesomeIcon icon={faCamera} />
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </div>
            </div>

            <div className="form-fields">
                <div className="form-group">
                    <div>
                        <label>الاسم</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            disabled={!!accountData} // Disable if editing existing account
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div>
                        <label>اسم العرض</label>
                        <input
                            type="text"
                            name="displayName"
                            value={formData.displayName}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div>
                        <label>كلمة المرور</label>
                        <input
                            type={accountData ? "text" : "password"}
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>نوع الحساب</label>
                    <select
                        name="userTypeValue"
                        value={formData.userTypeValue}
                        onChange={handleInputChange}
                    >
                        <option value="admin">مدير</option>
                        <option value="user">مستخدم</option>
                    </select>
                </div>
            </div>

            <button
                className="add-account-button"
                onClick={handleSaveAccount}
                disabled={!isFormValid() || isSubmitting}
            >
                {isSubmitting ? "جاري الإرسال..." : accountData ? "تعديل الحساب" : "إضافة حساب"}
            </button>

            {modalVisible && (
                <div className="modal-open">
                    <div className="modal-content">
                        {isSuccess && <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />}
                        <p>{modalContent}</p>
                        <button onClick={closeModal} className="close-modal-button">
                            إغلاق
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddAccount;
