import { useState, useEffect } from "react";
import { m, motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import Item from "./item";
import { useNavigate } from 'react-router-dom';
import { getCookie } from "../../Pages/Login/Login";
import LogOut from "../../Component/LogOut/LogOut";
import imgUser from "../../Component/img/team-01.png";
import "./Sidebar.css";

function Sidebar() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
    const [showHomeSubMenu, setShowHomeSubMenu] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setOpen(false);
            } else {
                setOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLogoutClick = () => {
        setLogoutDialogOpen(true);
    };

    const functionLogout = () => {
        document.cookie = `${encodeURIComponent("user")}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        navigate('/');
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleHomeClick = () => {
        setShowHomeSubMenu(prev => !prev);
    };

    const cookieValue = JSON.parse(getCookie("user")) || {};

    const sideContainerVariants = {
        true: { width: "13rem" },
        false: { width: "5rem", transition: { delay: 0.6 } },
    };

    const sidebarVariants = {
        true: { width: "100%" },
        false: { width: "5rem", transition: { delay: 0.4 } },
    };

    const userCardStyles = {
        padding: open ? "15px" : "10px 0px",
    };

    const userImageStyles = {
        width: open ? '50px' : '25px',
        height: open ? '50px' : '25px',
        marginRight: open ? '10px' : '0px',
    };

    const userNameStyles = {
        display: open ? 'block' : 'none',
    };

    const userButtonStyles = {
        fontSize: open ? '' : '8px',
        padding: open ? "10px 20px" : "5px",
        margin: open ? "10px 0px" : "10px 5px",
    };

    return (
        <>
            <motion.div
                data-open={open}
                variants={sideContainerVariants}
                initial={`${open}`}
                animate={`${open}`}
                className="sidebar_container"
            >
                <motion.div
                    className="sidebar"
                    initial={`${open}`}
                    animate={`${open}`}
                    variants={sidebarVariants}
                >
                    <div className="sidebar-contant">
                        <motion.div
                            whileHover={{
                                scale: 1.2,
                                rotate: 180,
                                backgroundColor: "rgba(255, 255, 255, 0.3)",
                                backdropFilter: "blur(3.5px)",
                                border: "1px solid rgba(255, 255, 255, 0.18)",
                                transition: { delay: 0.2, duration: 0.4 },
                            }}
                            onClick={handleToggle}
                            className="lines_icon"
                            aria-label="Toggle Sidebar"
                        >
                            <FontAwesomeIcon icon={faListUl} />
                        </motion.div>

                        {/* Menu Items */}
                        <div className="group">
                            <Item id="one" icon="fa-duotone fa-solid fa-chart-pie-simple" name="التحكم" link="/home-page" />
                        </div>
                        <div className="group">
                            <Item id="two" icon="fa-light fa-chart-simple" name="ادارة الفواتير" link="/billing-management" />
                        </div>
                        <div className="group">
                            <Item id="three" icon="fa-light fa-cart-shopping" name="بحث الفواتير" link="/search-invoices" />
                        </div>
                        <div className="group">
                            <div onClick={handleHomeClick} className="menu-item" id="four">
                                <Item icon="fa-light fa-bag-shopping" name="الرئيسيه" link="#" />
                                <motion.i
                                    className={`fa ${showHomeSubMenu ? 'fa-chevron-down' : 'fa-chevron-right'}`}
                                    animate={{ rotate: showHomeSubMenu ? 90 : 0 }}
                                    transition={{ duration: 0.2 }}
                                />
                            </div>

                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{
                                    height: showHomeSubMenu ? 'auto' : 0,
                                    opacity: showHomeSubMenu ? 1 : 0,
                                }}
                                transition={{ duration: 0.5, ease: 'easeInOut' }}
                            >
                                <div className="sub-menu">
                                    <Item icon="fa-light fa-circle-dot" name="المحافظات" link="/home/governorates" isSubMenu={true} />
                                    <Item icon="fa-light fa-circle-dot" name="مجموعة الاصناف" link="/home/items-group" isSubMenu={true} />
                                    <Item icon="fa-light fa-circle-dot" name="الاصناف" link="/home/categories" isSubMenu={true} />
                                    <Item icon="fa-light fa-circle-dot" name="المندوب" link="/home/delegate" isSubMenu={true} />
                                </div>
                            </motion.div>
                        </div>
                        <div className="group">
                            <Item id="five" icon="fa-light fa-chart-line" name="الفواتير" link="/invoices" />
                        </div>
                        <div className="group">
                            <Item id="six" icon="fa-light fa-chart-line" name="ادارة الموقع" link="/accounts" />
                        </div>
                        <div className="group">
                            <Item id="seven" icon="fa-light fa-message-dots" name="الرسائل" link="/chat-app" />
                        </div>
                    </div>

                    <div className="group">
                        {cookieValue && (
                            <motion.div className="user-card" style={userCardStyles}>
                                <img src={imgUser} alt="User" className="user-card-img" style={userImageStyles} />
                                <div className="user-card-info">
                                    <h4 style={userNameStyles}>{cookieValue.displayName}</h4>
                                    <p style={userNameStyles}>{cookieValue.email}</p>
                                </div>
                                <button className="logout-button" style={userButtonStyles} onClick={handleLogoutClick}>
                                    تسجيل الخروج
                                </button>
                            </motion.div>
                        )}
                    </div>
                </motion.div>

            </motion.div>
            <LogOut
                open={logoutDialogOpen}
                onClose={() => setLogoutDialogOpen(false)}
                onConfirm={functionLogout}
            />
        </>
    );
}

export default Sidebar;
