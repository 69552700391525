import React, { useState } from 'react';
import './ChatApp.css';

const SidebarChat = ({ friends, onFriendSelect, selectedFriendId }) => {
    return (
        <div className="SidebarChat" di>
            <div className="search">
                <input type="text" placeholder="ابحث عن شخص" />
            </div>
            {friends.map(friend => (
                <div
                    key={friend.id}
                    className={`friend ${selectedFriendId === friend.id ? 'active' : ''}`}
                    onClick={() => onFriendSelect(friend.id)}
                >
                    <div className="friend-info">
                        <h4>{friend.name}</h4>
                        <p>{friend.lastMessage}</p>
                    </div>
                    <img src={friend.avatar} alt={`${friend.name}'s avatar`} className="friend-avatar" />

                </div>
            ))}
        </div>
    );
};

export default SidebarChat;
