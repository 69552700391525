import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  fetchDataWithRetries,
  postDatatoQueryParams,
  putDataToQueryParams,
  deleteFunction,
} from '../../../Component/function/FunctionApi';
import './Governorate.css';

const API_ENDPOINTS = {
  FETCH: 'Governorate/get-governorates',
  CREATE: 'Governorate/create-governorate',
  UPDATE: 'Governorate/update-governorate',
  DELETE: 'Governorate/governorate', 
};

const MESSAGES = {
  FETCH_ERROR: 'فشل في تحميل البيانات. يرجى المحاولة مرة أخرى لاحقًا.',
  UPDATE_SUCCESS: 'تم تعديل عدد أيام التوصيل بنجاح',
  CREATE_SUCCESS: 'تم إضافة المحافظة بنجاح',
  DELETE_SUCCESS: 'تم حذف المحافظة بنجاح',
  FORM_ERROR: 'يرجى ملء جميع الحقول بشكل صحيح.',
};

function ComponentGover() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newProvince, setNewProvince] = useState({ name: '', deliveryDays: '' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    fetchGovernorates();
  }, []);

  const fetchGovernorates = async () => {
    try {
      setLoading(true);
      setError(null);
      await fetchDataWithRetries(API_ENDPOINTS.FETCH, setApiData);
    } catch (err) {
      setError(MESSAGES.FETCH_ERROR);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = (editMode = false, province = { name: '', deliveryDays: '' }) => {
    setIsEditMode(editMode);
    setNewProvince(province);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewProvince({ name: '', deliveryDays: '' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProvince((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    return newProvince.deliveryDays >= 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      setError(MESSAGES.FORM_ERROR);
      return;
    }
  
    try {
      const params = {
        Name: newProvince.name,
        DeliveryDays: newProvince.deliveryDays,
      };
  
      if (isEditMode) {
        // لا نحتاج لإرسال ID
        await putDataToQueryParams(API_ENDPOINTS.UPDATE, params);
        setSuccessMessage(MESSAGES.UPDATE_SUCCESS);
      } else {
        await postDatatoQueryParams(API_ENDPOINTS.CREATE, newProvince);
        setSuccessMessage(MESSAGES.CREATE_SUCCESS);
      }
  
      await fetchGovernorates();
      setShowSuccessPopup(true);
      setTimeout(closeSuccessPopup, 3000);
    } catch (err) {
      setError(`خطأ: ${err.message || 'فشل في حفظ البيانات. يرجى المحاولة مرة أخرى لاحقًا.'}`);
    } finally {
      handleCloseModal();
    }
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const handleDeleteConfirmation = (province) => {
    setItemToDelete(province.id);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteFunction(`${API_ENDPOINTS.DELETE}?id=${itemToDelete}`); // Call the delete function
      setSuccessMessage(MESSAGES.DELETE_SUCCESS);
      await fetchGovernorates();
      setShowSuccessPopup(true);
      setTimeout(closeSuccessPopup, 3000);
    } catch (err) {
      setError(`خطأ: ${err.message || 'فشل في حذف البيانات. يرجى المحاولة مرة أخرى لاحقًا.'}`);
    } finally {
      setIsDeleteModalOpen(false);
      setItemToDelete(null);
    }
  };

  return (
    <div className="provinces-container">
      <div className="header-section">
        <h2>المحافظات</h2>
        <button className="add-button" onClick={() => handleOpenModal(false)}>
          اضافه محافظه +
        </button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <table className="provinces-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>الاسم</th>
              <th>عدد أيام التوصيل</th>
            </tr>
          </thead>
          <tbody>
            {apiData.data && apiData.data.length > 0 ? (
              apiData.data.map((province, index) => (
                <tr key={province.id} onClick={() => handleOpenModal(true, province)}>
                  <td>{index + 1}</td>
                  <td>{province.name}</td>
                  <td>{province.deliveryDays}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>{isEditMode ? 'تعديل عدد أيام التوصيل' : 'محافظة جديدة'}</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <hr />
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group">
                  <label>الاسم</label>
                  <input
                    type="text"
                    name="name"
                    value={newProvince.name}
                    onChange={handleInputChange}
                    placeholder="الاسم"
                    readOnly={isEditMode}
                  />
                </div>
                <div className="form-group">
                  <label>عدد أيام التوصيل</label>
                  <input
                    type="number"
                    name="deliveryDays"
                    value={newProvince.deliveryDays}
                    onChange={handleInputChange}
                    placeholder="عدد أيام التوصيل"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleSave}>
                <FontAwesomeIcon icon={faSave} /> {isEditMode ? 'تحديث' : 'حفظ'}
              </button>
              {isEditMode && (
                <button 
                  className="action-button" 
                  onClick={() => handleDeleteConfirmation(newProvince)}
                >
                  <FontAwesomeIcon icon={faTrash} /> حذف
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {showSuccessPopup && (
        <div className="modal-overlay Success-modal">
          <div className="modal-content">
            <div className="modal-body">
              <p>{successMessage}</p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={closeSuccessPopup}>
                إغلاق
              </button>
            </div>
          </div>
        </div>
      )}
      {isDeleteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>تأكيد الحذف</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={() => setIsDeleteModalOpen(false)}
              />
            </div>
            <hr />
            <div className="modal-body">
              <p>هل أنت متأكد أنك تريد حذف هذه المحافظة؟</p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleDelete}>
                تأكيد الحذف
              </button>
              <button className="action-button" onClick={() => setIsDeleteModalOpen(false)}>
                إلغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ComponentGover;
