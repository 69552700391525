import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { GridToolbar } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave} from '@fortawesome/free-solid-svg-icons';


// Define options for dropdown fields
const governorateOptions = ['الفيوم', 'القاهره'];
const categoriesOptions = ['سلوبيت ازرق مقا', 'تيشرت ازرق ي'];
const statusOptions = ['جديد', 'مستعمل'];

const columns = [
  { field: 'id', headerName: 'كود', width: 100, sortable: true },
  { field: 'date', headerName: 'التاريخ', width: 100, sortable: true },
  { field: 'client', headerName: 'العميل', width: 100, sortable: true },
  { field: 'address', headerName: 'العنوان', width: 100, sortable: true },
  { field: 'governorate', headerName: 'المحافظة', width: 100, sortable: true },
  { field: 'phone', headerName: 'التليفون', width: 100, sortable: true },
  { field: 'categories', headerName: 'الاصناف', width: 100, sortable: true },
  { field: 'itemCount', headerName: 'العدد', width: 100, sortable: true, type: 'number' },
  { field: 'DailyHistory', headerName: 'تاريخ اليومية', width: 100, sortable: true },
  { field: 'delegate', headerName: 'المندوب', width: 100, sortable: true },
  { field: 'status', headerName: 'الحالة', width: 100, sortable: true },
  {
    field: 'total',
    headerName: 'الاجمالي',
    width: 130,
    sortable: true,
    type: 'number',
    valueFormatter: ({ value }) => new Intl.NumberFormat('ar-EG', { style: 'currency', currency: 'EGP' }).format(value),
  },
];

const rows = [
    { id: 19958695, date: '2024/9/2', client: 'شمس محمد', address: "الفيوم /الفيوم /دلا", governorate: "الفيوم", phone: "01020870630", categories: "سلوبيت ازرق مقا", itemCount: 2, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد" ,total: 100  },
    { id: 24968948, date: '2024/9/2', client: 'شمس محمد', address: "يؤغيؤيؤنشسش", governorate: "القاهره", phone: "01020870631", categories: "تيشرت ازرق ي", itemCount: 3, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد" ,total: 580 },
    { id: 30496049, date: '2024/9/2', client: 'محمد حسن', address: "الفيوم /الفيوم /دلا", governorate: "الفيوم", phone: "01020870633", categories: "سلوبيت ازرق مقا", itemCount: 7, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد" ,total: 130},
    { id: 40496046, date: '2024/9/2', client: 'محمد حسن', address: "الفيوم /الفيوم /دلا", governorate: "القاهره", phone: "01020870639", categories: "تيشرت ازرق ي", itemCount: 9, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد",total: 700 },
    { id: 54096049, date: '2024/9/2', client: 'شمس محمد', address: "الفيوم /الفيوم /دلا", governorate: "القاهره", phone: "01020870635", categories: "سلوبيت ازرق مقا", itemCount: 5, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد",total: 560 },
    { id: 60469049, date: "2024/9/2", client: 'محمد حسن', address: "الفيوم /الفيوم /دلا", governorate: "الفيوم", phone: "01020870569", categories: "سلوبيت ازرق مقا", itemCount: 3, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد" ,total: 50},
    { id: 70469049, date: '2024/9/2', client: 'شمس محمد', address: "الفيوم /الفيوم /دلا", governorate: "الفيوم", phone: "01020870930", categories: "تيشرت ازرق ي", itemCount: 1, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد",total: 560 },
    { id: 8049049, date: '2024/9/2', client: 'شمس محمد', address: "الفيوم /الفيوم /دلا", governorate: "القاهره", phone: "01020870630", categories: "سلوبيت ازرق مقا", itemCount: 3, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد" ,total: 560},
    { id: 94096040, date: '2024/9/2', client: 'محمد حسن', address: "الفيوم /الفيوم /دلا", governorate: "القاهره", phone: "01020870630", categories: "سلوبيت ازرق مقا", itemCount: 9, DailyHistory: '2024/9/2', delegate: "محمد عادل", status: "جديد" ,total: 157},
  ];
const paginationModel = { page: 0, pageSize: 5 };

export default function DataTable() {
  const [open, setOpen] = React.useState(false);
  const [selectedCell, setSelectedCell] = React.useState(null);
  const [cellValue, setCellValue] = React.useState('');
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleCellClick = (params) => {
    setSelectedCell(params);
    setSelectedRow(params.row);
    setCellValue(params.value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    // Update the row data with new value
    const updatedRows = rows.map(row =>
      row.id === selectedRow.id ? { ...row, [selectedCell.field]: cellValue } : row
    );
    // Update the rows with the new data
    // Here you should ideally update the state or context that holds the rows data
    console.log('Updated rows:', updatedRows);
    setOpen(false);
  };

  const handleChange = (e) => {
    setCellValue(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setCellValue(e.target.value);
  };

  const renderInputField = () => {
    switch (selectedCell.field) {
      case 'date':
      case 'DailyHistory':
        return (
          <TextField
            autoFocus
            margin="dense"
            id="value"
            label="تاريخ"
            type="date"
            fullWidth
            variant="outlined"
            value={cellValue}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        );
      case 'client':
      case 'address':
      case 'delegate':

        return (
          <TextField
            autoFocus
            margin="dense"
            id="value"
            label={columns.find(col => col.field === selectedCell.field).headerName}
            type="text"
            fullWidth
            variant="outlined"
            value={cellValue}
            onChange={handleChange}
          />
        );
      case 'governorate':
      case 'categories':
      case 'status':
        return (
          <TextField
            select
            autoFocus
            margin="dense"
            id="value"
            label={columns.find(col => col.field === selectedCell.field).headerName}
            fullWidth
            variant="standard"
            value={cellValue}
            onChange={handleDropdownChange}
          >
            {(selectedCell.field === 'governorate' ? governorateOptions :
              selectedCell.field === 'categories' ? categoriesOptions : statusOptions)
              .map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        );
      case 'phone':
      case 'itemCount':
      case 'total':
        return (
          <TextField
            autoFocus
            margin="dense"
            id="value"
            label={columns.find(col => col.field === selectedCell.field).headerName}
            type="number"
            fullWidth
            variant="outlined"
            value={cellValue}
            onChange={handleChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10, 50]}
          pageSize={paginationModel.pageSize}
          paginationMode="client"
          rowsPerPageOptions={[10, 50, { value: -1, label: 'All' }]}
          checkboxSelection
          components={{ Toolbar: GridToolbar }}
          sx={{ border: 0, direction: 'rtl' }}
          onCellClick={handleCellClick}
        />
      </Paper>

      <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        className: 'dialog-paper'
      }}
    >
      <div className="dialog-container" dir='rtl'>
        <DialogTitle className="dialog-title">
          تعديل {selectedCell &&  <>{columns.find(col => col.field === selectedCell.field).headerName}</>}
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ padding: '16px' , direction: "rtl"}}>
        {selectedCell && (
          <>
            {/* <p>{columns.find(col => col.field === selectedCell.field).headerName}</p> */}
            {renderInputField()}
          </>
        )}
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button onClick={handleClose} className="cancel-button">
          إلغاء
        </Button>
        <Button onClick={handleSave} className="save-button">
        حفظ
        <FontAwesomeIcon icon={faSave} />
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}
